body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.toolbar-container {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  flex-grow: 1;
  justify-content: space-between;
}

.toolbar-element {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.toolbar-end {
  justify-content: flex-end;
}

.toolbar-centre {
  flex-grow: 1;
  justify-content: center;
  gap: 30px;
}

tr.user-table-disabled td:not(.actions){
  opacity: 0.25;
}

.app-content-container {
  display: flex;
  flex: 1 0;
  flex-direction: row;
}

.menu-button-container {
  height: 100%;
}

.menu-button-container > button {
  height: 100%;
}

.expanded-darkener {
  z-index: 10;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.25);
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-datepicker-popper {
  z-index: 12 !important;
}

@media (max-width: 900px) {
  .toolbar-start, .toolbar-end { flex: 1 1 50%; }
  .toolbar-start { order: 0; }
  .toolbar-end { order: 1; }
  .toolbar-start > h6 { display: none; }
  .sm-removed { display: none !important; }

  .toolbar-centre {
    order: 2;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    width: 100%;
  }
}

@media (max-width: 600px) {
  .app-content-container { flex-direction: column; }
  .menu-button-container { width: 100%; height: auto; }
  .menu-button-container > button { width: 100%; }
  .app-content-container > div { box-sizing: unset; }
  .window-container { width: auto !important; max-width: unset !important; }

  .xs-removed { display: none !important; }
}