/* TabStyles.css */
.react-tabs {
    -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
    border-bottom: 1px solid #aaa;
    margin: 0;
    padding: 0;
    display: flex;
    list-style-type: none;
}

.react-tabs__tab:focus:after {
    content: "";
    position: absolute;
    height: 5px;
    left: -4px;
    right: -4px;
    bottom: -5px;
    background: #fff;
}

.react-tabs__tab-panel {
    border: 1px solid #aaa;
    border-top: none; /* Remove the top border if it's not necessary */
    padding: 10px;
    background: #fff;
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}


/* Custom Tab Styles */
.react-tabs__tab {
    border: 1px solid #aaa;
    border-bottom: none; /* Merge visually with the tab content */
    background-color: #fafafa; /* Deselected background color */
    margin-right: 2px;
    padding: 6px 12px;
    user-select: none;
    cursor: pointer;
    outline: none; /* Remove default focus outline */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
    margin-bottom: -1px; /* Pull the tab down to cover any gaps */
}

.react-tabs__tab--selected {
    background-color: #fff; /* Selected background color */
    border-color: #aaa;
    border-bottom: 1px solid white; /* To blend with the content box, should match the tab-panel's background color */
    position: relative;
    z-index: 1;
}

.react-tabs__tab:focus {
    /* Instead of removing, provide a visual cue for accessibility */
    background-color: #e9e9e9; /* Slightly different to indicate focus */
    /* Optionally, add a box-shadow or border style that suits your design for focus */
}
