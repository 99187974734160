data-cell {
    text-align: center;
    border: 1px solid #ddd; /* Add border property to cells */
}

.grid-table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
}

.data-cell.selected {
    background-color: #abcdef; /* Choose a highlight color */
}

.date-picker-container {
    display: flex;
    justify-content: center; /* Centers the content of the date picker */
    width: 100%; /* Takes full width of its parent to align with the grid below */
}

.no-select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, supported by Chrome, Firefox, and Opera */
}

.title-cell,
.hour-cell,
.day-cell,
.hour-title-cell {
    text-align: center;
    width : 15px;
    padding: 4px;
    font-weight: bold;
    border: 1px solid #ddd; /* Add border property to cells */
    background-color: #0093cb;
    color: white;
    font-size: 12px;
}

.therapist-cell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 150px; /* Changed from max-width to width */
}


.grid-table {
    width: 100%;
    max-width: 100%;
    table-layout: fixed;
    overflow-x: auto;
    cursor: pointer;
    padding: 20px;
    padding-top: 0px;
    z-index: 10;
}


/* Responsive font sizes for better flexibility */
.grid-title-date-picker {
    font-size: 2vw; /* Example of responsive font size */
}

.grid-cell {
    position: relative;
}

.grid-cell:before {
    content: '';
    display: block;
    padding-top: 80%; /* This keeps the aspect ratio 1:1 */
}

.grid-content {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;

    /* Add text styles or other content styling here */
}

.scrollable-table-container {
    max-height: 500px; /* Adjust based on your layout */
    overflow-y: auto;
}

.hour-title-cell {
    width: 150px; /* Adjust this width as needed */
    min-width: 150px; /* Ensures the column does not shrink smaller than this width */
    text-align: center;
    padding: 4px;
    font-weight: bold;
    border: 1px solid #ddd; /* Add border property to cells */
    background-color: #0093cb;
    color: white;
    font-size: 12px;
}

.grid-table th, .grid-table td {
    box-sizing: border-box;
}

.grid-table th, .grid-table td {
    text-align: center;
    padding: 4px;
    border: 1px solid #ddd;
    box-sizing: border-box; /* Ensures padding and border are included in the width */
    min-width: 50px; /* Example minimum width */
}


.note-present::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    border-top: 10px solid #ffff00cc;
    border-left: 10px solid transparent;
    width: 0;
    height: 0;
}


