.datePickerSection {
    padding: 30px;
}

.datePickerRangeButtonContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
}

.datePickerModeButtonContainer {
    width: 100%;
    padding-bottom: 10px;
}

.datePickerContainer {
    justify-content: space-around;
    display: flex;
    padding: 30px;
    background-color: #595959;
}

@media (prefers-color-scheme: dark) {
    .datePickerContainer {
        background-color: #595959;
    }
}